 import { BrowserModule } from '@angular/platform-browser';
 import { NgModule } from '@angular/core';
 import {FormsModule} from "@angular/forms";
 import {Routes, RouterModule, RouterStateSnapshot, provideRoutes} from '@angular/router';
 import {ReactiveFormsModule} from '@angular/forms';
 import { AppComponent } from './app.component';
 import { AppRoutingModule } from './app-routing.module'; 
 import {HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
 import { RequestOverviewComponent } from './request-overview/request-overview.component';
 import { SharedService } from './Shared/shared.service'
 import { AuthService } from './auth/auth-service.service'
 //import { SharedComponent } from './Shared/shared.component';
 import {RequestOverviewService} from './request-overview/request-overview.service';
 import { AgGridModule } from '@ag-grid-community/angular';
 import { HashLocationStrategy, LocationStrategy } from '@angular/common';
 import { GridRequestFileComponent } from './grid-request-file/grid-request-file.component';
 import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 //import { NgxSpinnerModule } from "ngx-spinner";
import { RequestViewComponent } from './request-view/request-view.component';
import { ProjRefAndBudgetViewComponent } from './proj-ref-and-budget-view/proj-ref-and-budget-view.component';
import { SendRequestViewComponent } from './send-request-view/send-request-view.component';
import { SetApproverComponent } from './set-approver/set-approver.component';
//import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {customDateFormatPipe} from './customDateFormatPipe';
import {RequestDetailService} from './request-details/request-details.service';
import { DatePipe } from '@angular/common';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { StatusChangeComponent } from './status-change/status-change.component';
//import {TestComponent} from './test/test.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { CustomHttpInterceptor } from './Interceptors/http-interceptor';
import { AuthTokenInterceptorService } from './Interceptors/auth-token-interceptor.service';
import{CoreModule} from './core/core.module';
import { CookieService } from 'ngx-cookie-service';
import { ChangeInfoComponent } from './changeInfo/changeInfo.component';

import {MatDialogModule} from '@angular/material/dialog';
import { FirewallrulesComponent } from './request-details/firewallrules.component';
import { IsrComponent } from './isr/isr.component';
import { CreateNewRequestComponent } from './create-new-request/create-new-request.component';
import {MatCardModule} from '@angular/material/card';
import { MatAccordion } from '@angular/material/expansion';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CreateRequestInfoComponent } from './create-request-info/create-request-info.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgxMasonryModule } from 'ngx-masonry';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from "@angular/material/icon"; 
import {MatTooltipModule} from '@angular/material/tooltip';
import { ContinueUnfinishedRequestComponent } from './isr/continue-unfinished-request/continue-unfinished-request.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { MessageBoxComponent } from './message-box/message-box.component';
import { LoginComponent } from './login/login.component';
import { LoginOTPComponent } from './login-otp/login-otp.component'; 
//import { OAuthModule, provideOAuthClient } from 'angular-oauth2-oidc';
import { MsalModule, MsalRedirectComponent,MsalInterceptor,MsalGuard } from "@azure/msal-angular";
import { InteractionType,PublicClientApplication } from '@azure/msal-browser';
import { RequestApprovalComponent } from './request-approval/request-approval.component';
//import {MatMenuModule,MatMenu} from '@angular/material/menu'
//import { MatFormFieldModule } from "@angular/material/form-field";

// const ROUTES:Routes = [
//     {path:'isr', component: IsrComponent}
// ];

var client_Id='';
var Redirect_URL = '';
if(document.baseURI.indexOf("localhost")!=-1) {
  Redirect_URL = 'http://localhost:4200';
  client_Id = 'b55a5502-5d05-41d2-a912-a4daa9b6d6ec';
} else if (document.baseURI.indexOf("isrtest")!=-1){
Redirect_URL = 'https://isrtest.enn.ericsson.net/v2/';
          client_Id = 'b55a5502-5d05-41d2-a912-a4daa9b6d6ec';
}else if(document.baseURI.indexOf("isr")!=-1) {           
  Redirect_URL = 'https://isr.enn.ericsson.net/v2/';
  client_Id = '2f5d852f-bc9d-445d-9b32-c4e56e4d0562';}


@NgModule({
    declarations: [
        AppComponent,
        RequestOverviewComponent,
        GridRequestFileComponent,
        RequestViewComponent,
        ProjRefAndBudgetViewComponent,
        SendRequestViewComponent,
        SetApproverComponent,
        RequestDetailsComponent,
        customDateFormatPipe,
        StatusChangeComponent,
        ChangeInfoComponent,
        FirewallrulesComponent,
        IsrComponent,
        CreateNewRequestComponent,
        CreateRequestInfoComponent,
        ProfileInfoComponent,
        ContinueUnfinishedRequestComponent,
        MessageBoxComponent,
        LoginComponent,
        LoginOTPComponent,          
        RequestApprovalComponent        
        //FaqComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
          
        BrowserAnimationsModule,
        //NgxSpinnerModule,  
        ReactiveFormsModule,
        HttpClientModule,       
        MatProgressBarModule,
        CoreModule,
        AgGridModule.withComponents([GridRequestFileComponent]),
        MatDialogModule,
        MatCardModule,  
        MatExpansionModule,      
        FlexLayoutModule,
        MatTabsModule,
        ScrollingModule,
        NgxMasonryModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        MatTooltipModule,
        TooltipModule,
        MsalModule.forRoot(
            new PublicClientApplication({
              auth: {
                clientId: client_Id, // Application (client) ID from the app registration
                authority:"https://login.microsoftonline.com/92e84ceb-fbfd-47ab-be52-080c6b87953f", // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
                //authority:"https://login.microsoftonline.com/common", // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
                redirectUri: Redirect_URL
                //redirectUri: "http://localhost:4200" // This is your redirect URI
                //redirectUri: "https://isrtest.enn.ericsson.net/v2/#/home"
                //redirectUri: "https://isrtest.enn.ericsson.net/v2/"
              },
              cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: false, // Set to true for Internet Explorer 11
              },
            }),
            null,
            null
          ),            
    ],
    // exports:[RouterModule],
    providers: [
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptorService,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true
        },            
        AuthService, SharedService, RequestOverviewService, RequestDetailService, DatePipe, CookieService
    ],
    bootstrap: [AppComponent,MsalRedirectComponent]
    //bootstrap: [LoginComponent]
})
export class AppModule {     
  // constructor(private sharedService:SharedService) {  
  //   if(document.baseURI.indexOf("localhost")==-1) {
  //     if(document.cookie.indexOf("ISTCookie2")==-1)
  //     {
  //       if(document.baseURI.indexOf("isrtest")!=-1) {
  //       window.location.href = "https://isrtest.enn.ericsson.net";
  //       }
  //       else if(document.baseURI.indexOf("isr")!=-1) {
  //         window.location.href = "https://isr.enn.ericsson.net";
  //       }
  //     } else{
  //       var su_fwchanges;
  //       su_fwchanges = document.cookie.substring(document.cookie.indexOf("su_fwchanges"),document.cookie.indexOf("Now")).replace("&",'').replace("username=",'').replace("su_fwchanges=",'')
  //        if(su_fwchanges=='False'){
  //         if(document.baseURI.indexOf("isrtest")!=-1) {
  //          window.location.href = "https://isrtest.enn.ericsson.net";
  //         }
  //         else if(document.baseURI.indexOf("isr")!=-1) {
  //           window.location.href = "https://isr.enn.ericsson.net";
  //         }
  //        }      
  //     }
  //   }    
  // }
 } 
