<h2 mat-dialog-title>Unfinished request</h2>
<mat-dialog-content>
<!-- The Modal -->
<div id="ContinueUnfinishedRequestModal">
   
    
        <div class="row rh-request">
            <div class="col-lg-12" >
            <div class="panel panel-default">
                                
                <div class="panel-body">                
                    
                <p class="contentText">
                    You have an unfinished request.<br />
                        Do you want to finish it now?<br />
                        <br />
                        Click CONTINUE to finish this request;<br />                        
                        Click NEW to discard this request and start a new one;<br />
                        Click CANCEL to return to the menu!
                </p>
                    
                </div>

            </div>
            </div>
        </div>        
          
  </div>

</mat-dialog-content>

<mat-dialog-actions align="end">

    <div class="footer">
        <span>
            <button type="button" class="ebBtn" (click) = "Continue()">CONTINUE</button>
        </span>
        <span>
            <button type="button" class="ebBtn" (click) = "New()">NEW</button>
        </span>
    <span>
        <button type="button" class="ebBtn" (click) = "close()">CANCEL</button>
    </span>
    </div>

</mat-dialog-actions>
