import { Component, OnDestroy, OnInit } from '@angular/core';
import { Routes, Event, Router, NavigationStart, NavigationEnd } from '@angular/router';
import {ProgressBarService} from './utility/ProgressBar.service';
import { AuthService } from './auth/auth-service.service';
import { CookieService } from 'ngx-cookie-service';
import {SharedService} from './Shared/shared.service';
import {GlobalParamsService} from './global/globalParams.service';
import {UserInfoService} from './global/userInfo.service';
import{userModel} from './model/user.model';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Subject } from 'rxjs';
import { LoginOTPService } from './login-otp/login.service';
import { MenuService } from './global/menu.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [userModel]
})
export class AppComponent implements OnInit,OnDestroy {
  formData: FormData = new FormData();
  isDataAvailable:boolean=false;
  devEnv:string=''; 
  //showLoadingIndicator = false;
  private readonly _destroying$ = new Subject<void>();
  loggedUserName: string;
  ISTCookieValue: string;
  ISRv1Version:string;
  isEricsson:Boolean;
  islogin:Boolean=true;
  /*public hideheader={
    "container":!this.islogin,
    "containerlogin":this.islogin,
}*/
  constructor(private _router: Router,private cookieSvc: CookieService,
    private globalParamsService:GlobalParamsService,
    private sharedService:SharedService, 
    private authSvc:AuthService,private progressBarService: ProgressBarService, 
    private objUserModel:userModel, private objUserInfoService:UserInfoService,
    private msalAuthService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private loginOtpService: LoginOTPService,
    private menuService:MenuService
    ) {
    }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
   // throw new Error('Method not implemented.');
  }
   ngOnInit() { 
      this.menuService.islogin.subscribe(log=>this.islogin=log);
      var ISTCookie = this.cookieSvc.get('ISTCookie2');
      
      var email ="";
      var userEmail ="";   
      
      
 /* this.msalBroadcastService.inProgress$
  .pipe(
    filter((status: InteractionStatus) => status === InteractionStatus.None),
    takeUntil(this._destroying$)
  )
  .subscribe(async () => {
    if(this.msalAuthService.instance.getAllAccounts().length>0){
    //console.log("login ok");
    }
    //this.setLoginDisplay();
  });

  this.msalAuthService.handleRedirectObservable().subscribe({
    next: (result: AuthenticationResult) => {
       if (//!this.msalAuthService.instance.getActiveAccount() &&             
          this.msalAuthService.instance.getAllAccounts().length > 0) {
            console.log("hello god");
            this._router.navigate(['/home'])
            // this.msalAuthService.instance.setActiveAccount(result.account);
          }
       },
    error: (error) => console.log(error)
 });*/

      //this.globalParamsService.isEricssonLogin.subscribe(isEric=>this.isEricsson=isEric); 
      //this.globalParamsService.isEricssonLogin.subscribe(isEric=>this.isEricssonLogin=isEric); 
      //var email=document.cookie.split(";")[1].split("=")[2].split("&")[0]  
      if(window.localStorage.getItem("loginState") == 'eric')
      {
        window.localStorage.setItem("loginState","eric-inp");
        //this._router.navigate(['/login']);
      }else{  
        var loginState = window.localStorage.getItem("loginState");
        if(loginState =='eric-comp')
    {this.isEricsson = true} else {this.isEricsson = false}

      if(ISTCookie != "" && ((this.isEricsson &&
         this.msalAuthService.instance.getAllAccounts().length>0) 
         || !this.isEricsson))
      { 
        this.islogin = false;      
        this.loginOtpService.setCookieData(ISTCookie);
        if( this.msalAuthService.instance.getAllAccounts().length > 0){
        this.objUserInfoService.userEmail = this.msalAuthService.instance.getAllAccounts()[0].username;
      }
        /*this.ISRv1Version=this.cookieSvc.get('ISRv1Version');
      this.globalParamsService._version.next(this.ISRv1Version);
       // alert('stet');
      email=ISTCookie.split("=")[1].split("&")[0]
      this.cookieSvc.set('userEmail',email); */

      /*var queryString= window.location.href.split("?")[1];
      if(queryString != '' && queryString != undefined && queryString != null){
      userEmail=queryString.split("&")[0].split("=")[1];*/
              
      }
      else {
        this.islogin = true;      
      /*  this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        console.log(result)
        if(this.msalAuthService.instance.getAllAccounts().length>0)
        {
          console.log("redirected");
        //this._router.navigate(['/home']);
        }
        //console.log(result);
      });*/
      
      this.globalParamsService.developmentEnv.subscribe(devEnv=>this.devEnv=devEnv); 
      //this._router.navigate(['/login']);
      this.sharedService.logout();
       /*this.sharedService.showMessageBox("Please Log In with the correct user.")
       
        window.location.href= this.sharedService.ISR_URL + "Default.aspx";    */
       
        return;
      } 
    }
      /* this.objUserInfoService.userEmail=email;
            this.formData.append('email', email);
       
       this.authSvc.getToken(this.formData).subscribe(val=>
          {
            window.localStorage.setItem("token",val) 
            //this.isDataAvailable=true;

            this.loggedUserName = email;
            
            if(this.loggedUserName!='') {    
              this.objUserModel.email=this.loggedUserName;      
              this.sharedService.GetUserByEmail(this.objUserModel).subscribe(data=>{
                this.objUserModel=data.table[0];         
                this.sharedService.getUserPermission(this.objUserModel).subscribe(data=>{
                   var _userPerm =  data;
                   window.localStorage.setItem("userPermission",JSON.stringify(_userPerm)) 
                   this.isDataAvailable=true;
                });                 
              }); 
             }
          }
        );*/
     }
}
