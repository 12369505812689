import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SharedService } from '../Shared/shared.service'
import { IsrComponentService } from './isr.component.service'
import { UserInfoService } from '../global/userInfo.service'
import { ContinueUnfinishedRequestComponent } from './continue-unfinished-request/continue-unfinished-request.component'
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
@Component({
  selector: 'app-isr',
  templateUrl: './isr.component.html',
  styleUrls: ['./isr.component.css']
})
export class IsrComponent implements OnInit {
  su_fwchanges: boolean = false;
  showUnfinishedRequest: boolean = false;
  emailformData: FormData = new FormData();
  constructor(private router: Router, private cookieSvc: CookieService, private sharedSvc: SharedService, private isrService: IsrComponentService, private objUserInfoService: UserInfoService, public dialog: MatDialog) { }

  ngOnInit(): void {
    // var searchElement = document.getElementById('qSearchInfo');
    // searchElement.style.display = "block";

    if (document.cookie.indexOf("su_fwchanges=True") != -1) {
      this.su_fwchanges = true;
    } else {
      this.su_fwchanges = false;
    }

    if (sessionStorage.getItem('isprofile') == "true") {
      sessionStorage.setItem('isprofile', 'false');
      window.location.reload();
    }

    //Check For Unfinished Requests
    this.emailformData.append('email', this.objUserInfoService.userEmail)

    this.isrService.CheckForUnfinished(this.emailformData).subscribe(data => {
      if (data.length > 0) {
        if (data[0].projectNummer != '') {
          this.showUnfinishedRequest = true;
        } else {
          this.showUnfinishedRequest = false;
        }
      } else {
        this.showUnfinishedRequest = false;
      }
    })
  }

  btnClick = function () {
    if (this.showUnfinishedRequest == true) {
      this.showContinueUnfinishedRequestDialog();
    } else {
      this.router.navigate(['/createNewRequest']);
    }
  };

  showContinueUnfinishedRequestDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    const dialogRef = this.dialog.open(ContinueUnfinishedRequestComponent, dialogConfig);
  }

  btnRoClick = function () {
    this.router.navigate(['/ro']);
  }

  btnProfileClick = function () {
    this.router.navigate(['/profileInfo']);
  };

  btnOpenRequestClick = function () {
    this.cookieSvc.set('V2CreateRequest', 'myOpenRequest', { path: '/' });
    window.location.href = this.sharedSvc.ISR_URL + "Default.aspx";
  };

  btnClosedRequestClick = function () {
    this.cookieSvc.set('V2CreateRequest', 'myCloseRequest', { path: '/' });
    window.location.href = this.sharedSvc.ISR_URL + "Default.aspx";
  };

}
