import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {SharedService} from '../Shared/shared.service'
import { promise } from 'protractor';
import { emailDefinitionModel } from '../model/user.model';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { LoginService } from '../login/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public service: LoginService, public router: Router,
    public sharedService: SharedService,
    private cookieService:CookieService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(window.localStorage.getItem('isAuthorized') == 'true'  && this.cookieService.get('ISTCookie2')!='' && 
    this.cookieService.get('ISTCookie2') != null ){
    return true;}
    else
    {
      //yet to uncomment 
     //this.sharedService.logout();
     console.log('redirect')
      this.router.navigate(['/login']);
    }
    //throw new Error('Method not implemented.');
  } 
}
