import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {SharedService} from '../Shared/shared.service'
import { promise, element } from 'protractor';
import { validateOtp } from '../model/user.model';
import { DatePipe } from '@angular/common';
import { AuthService } from '../auth/auth-service.service';
import { userModel } from '../model/user.model';
import { CookieService } from 'ngx-cookie-service';
import { GlobalParamsService } from '../global/globalParams.service';
import {UserInfoService} from '../global/userInfo.service';

@Injectable({
  providedIn: 'root'
})
export class LoginOTPService {
//getting token
  formData: FormData = new FormData();
  loggedUserName: string;
  ISTCookieValue: string;
  ISRv1Version:string;
  objUserModel:userModel = new userModel();
  constructor(private _http: HttpClient,private sharedService:SharedService,
    public datepipe: DatePipe,private authSvc:AuthService,
    private cookieSvc: CookieService, private globalParamsService:GlobalParamsService,private objUserInfoService:UserInfoService) { }

  ValidateOTP(validateOtp:validateOtp):Observable<any>{
    return this._http.post(this.sharedService.API_URL + '/api/ValidateOTP',validateOtp)
  };

  saveWebLog(validateOtp:validateOtp):Observable<any>{
    return this._http.post(this.sharedService.API_URL + '/api/saveWebLog',validateOtp)
  };

  setCookieValue(data){
    try{
    /*let date: Date = new Date();
    let expDate = date.setDate(date.getDate() + 1);
    let currentDateTime =this.datepipe.transform((new Date), 'dd MMM yyyy HH:mm:ss');
    let expiryTime = this.datepipe.transform(expDate, 'dd MMM yyyy HH:mm:ss');*/
    var cookieval =  data.table1[0].result;    
    if(data.table3 !=null){         
    cookieval = cookieval + "&devmode=" + String(data.table3[0].devmode);
    }
    else
    {
      cookieval = cookieval + "&devmode=false";
    }
    //cookieval = cookieval + "&ValidTo=" + data.table2[0].validTo;
    //cookieval = cookieval + "&IsOneTimeUser=" + String(data.table2[0].isOneTimeUser);
    //cookieval = cookieval + "&Now=" + currentDateTime;
    //cookieval = cookieval + "&expires=" + expiryTime;
  
    //this.cookieService.set('ISTCookie3',cookieval);
    document.cookie  = 'ISTCookie2=' + cookieval + ";path=/";
    document.cookie = 'ISRv1Version=' + '1.38.5' + ";path=/";    
    //document.cookie = "myCookie=" + JSON.stringify({foo: 'bar', baz: 'poo'});
    var ISTCookie = this.cookieSvc.get('ISTCookie2');
    this.setCookieData(ISTCookie);
    }
    catch(e)
    {
      console.log(e);
    }
  }

 getToken(email){
    //For getting token  
    return new Promise<void>((resolve, reject) => {
        this.formData = new FormData();
  this.formData.append('email', email);
  this.authSvc.getToken(this.formData).subscribe(val=>
  {
    window.localStorage.setItem("token",val) 
    //this.isDataAvailable=true;
  
    this.loggedUserName = email;
    
    if(this.loggedUserName!='') {    
      this.objUserModel.email=this.loggedUserName;      
      this.sharedService.GetUserByEmail(this.objUserModel).subscribe(data=>{
        this.objUserModel=data.table[0];         
        this.sharedService.getUserPermission(this.objUserModel).subscribe(data=>{
           var _userPerm =  data;
           window.localStorage.setItem("userPermission",JSON.stringify(_userPerm))
           resolve();
      
           //this.isDataAvailable=true;
        });                 
      }); 
     }
  }
  );
    
});

  }

  setCookieData(ISTCookie){    
  this.ISRv1Version=this.cookieSvc.get('ISRv1Version');
      this.globalParamsService._version.next(this.ISRv1Version);
       // alert('stet');
      var email=ISTCookie.split("=")[1].split("&")[0]
     
      /*var queryString= window.location.href.split("?")[1];
      if(queryString != '' && queryString != undefined && queryString != null){
      userEmail=queryString.split("&")[0].split("=")[1];*/
      this.cookieSvc.set('userEmail',email);  
      this.objUserInfoService.userEmail = email;
  }
}


