import { SubTypeOfRequests } from './../model/enum.model';
import { Component, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateRequestInfoService } from './create-request-info.service';
import { CookieService } from 'ngx-cookie-service';
import { Location } from '@angular/common';
import { logging } from 'protractor';
import { SharedService } from '../Shared/shared.service'
import { root } from 'rxjs/internal/util/root';
import { GlobalParamsService} from '../global/globalParams.service';

@Component({
  selector: 'app-create-request-info',
  templateUrl: './create-request-info.component.html',
  styleUrls: ['./create-request-info.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateRequestInfoComponent implements OnInit {
  requestType: string = '';
  description: string = '';
  output: string = '';
  exclusions: string = '';
  delivery: string = '';
  escalation: string = '';
  escalationHead: string = '';
  escalationData: string = '';
  windowHt: string = '';
  queryval: string = '';
  requestTitle: string = '';
  extraMsg: string = '';
  public user: any;
  isImgscale: boolean = false;
  reqParentType: string = '';
  reqParentTypeDesc: string = '';
  reqSubType: string ='';
  reqSubTypeDesc: string = '';

  constructor(private createRequestInfoSVC: CreateRequestInfoService,
    private router: Router, private route: ActivatedRoute,
    private cookieSvc: CookieService, private location: Location,
    private sharedSvc: SharedService, private elementRef: ElementRef,
    private globalParamsService:GlobalParamsService) { }

  ngOnInit(): void {
    try {
      // var searchElement = document.getElementById('qSearchInfo');
      // searchElement.style.display = "none";

      this.requestType = this.route.snapshot.paramMap.get('requestType');
      this.user = this.location.getState();
      this.GetRequestDefinition();
    }
    catch (e) {
      console.log(e);
    }
  }

  GetRequestDefinition() {
    try {
      this.isImgscale = false;
      this.createRequestInfoSVC.GetRequestDefinition(this.requestType).subscribe(data => {
        if (data != undefined && data != null && data.length > 0) {
          this.description = data[0].description;
          this.output = data[0].output;
          this.exclusions = data[0].exclusions;
          this.delivery = data[0].howToGet;
          this.escalation = data[0].escalation;
          this.escalationHead = data[0].escalation_head;
          this.escalationData = data[0].escalation_data;
          this.requestTitle = data[0].itmName;
          this.extraMsg = data[0].extraMessage;
         /* for migration
          //---          
          this.reqParentType = data[0].parentId;
          this.reqParentTypeDesc = data[0].parentItmname;  
          this.reqSubType = data[0].itmId;    
          this.reqSubTypeDesc = data[0].itmName;

          this.globalParamsService.requestParentType.next(this.reqParentType);
          this.globalParamsService.requestParentTypeDesc.next(this.reqParentTypeDesc);
          this.globalParamsService.requestSubType.next(this.reqSubType);
          this.globalParamsService.requestSubTypeDesc.next(this.reqSubTypeDesc);
        
          //-- */
          var divImg = document.getElementById('div_img');
          divImg.innerHTML = this.description;

          if (document.getElementById("process_Image") != undefined) {
            this.elementRef.nativeElement.querySelector('#process_Image').addEventListener('click', this.enlargeImg.bind(this));
          }

          var btn_create = document.getElementById('create_request');
          btn_create.focus();
        }
      });
    }
    catch (e) {
      console.log(e);
    }
  }

  btnClick = function () {
    this.router.navigate(['/createNewRequest']);
  };

  btnCreaterequestClick = function () {
    this.cookieSvc.set('V2CreateRequest', 'createrequest', { path: '/' });
    if (this.user.CodeTypeId != undefined && this.user.CodeSubTypeId != undefined) {
      this.cookieSvc.set('codeTypeId', this.user.CodeTypeId, { path: '/' });
      this.cookieSvc.set('codeSubTypeId', this.user.CodeSubTypeId, { path: '/' });
    }      
    window.location.href = this.sharedSvc.ISR_URL + "Default.aspx";
     //-- for migration 
   // this.router.navigate(['/reqView']);

  }

  enlargeImg() {
    console.log('test img');
    var img = document.getElementById("process_Image");
    img.style.paddingRight = "5px";
    if (this.isImgscale) {
      img.style.width = "800px";
      this.isImgscale = false;
    }
    else {
      img.style.width = "1220px";
      this.isImgscale = true;
    }
    /*if(img.style.width == "1200px"){
    img.style.width = "99.5";
    img.style.maxWidth = "800px";
  }
    else{
      img.style.width = "1200px";
    }*/
    /*console.log(img.style.transform); 
    if(img.style.transform=="")
    {       img.style.transform = "scale(1.8)";    }   
    else if(img.style.transform=="scale(1.8)")    
    {              img.style.transform = "scale(1)";   }
    else if(img.style.transform=="scale(1)")
    {     img.style.transform = "scale(1.8)";   }  */
    // Animation effect           img.style.transition = "transform 0.25s ease";        }     </script>
  }

}

