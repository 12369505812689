import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule,Router, NavigationEnd } from '@angular/router';
import { RequestOverviewComponent } from './request-overview/request-overview.component';
import { RequestViewComponent } from './request-view/request-view.component';
import { ProjRefAndBudgetViewComponent } from './proj-ref-and-budget-view/proj-ref-and-budget-view.component'
import { SendRequestViewComponent } from './send-request-view/send-request-view.component';
import { RequestDetailsComponent } from './request-details/request-details.component';
import { ChangeInfoComponent } from './changeInfo/changeInfo.component';
import { SharedService } from './Shared/shared.service';
import { IsrComponent } from './isr/isr.component';
import { CreateNewRequestComponent } from './create-new-request/create-new-request.component';
import { CreateRequestInfoComponent } from './create-request-info/create-request-info.component';
import { ProfileInfoComponent } from './profile-info/profile-info.component';
import { HeaderComponent } from './core/header/header.component';
import { AppComponent } from './app.component';
import {MenuService} from './global/menu.service';
import { LoginComponent } from './login/login.component';
import { LoginOTPComponent } from './login-otp/login-otp.component';
import { MsalGuard } from "@azure/msal-angular";
import { AuthGuard } from './auth/AuthGuard.service';
//import { LoginComponent } from './login/login.component';
//import {TestComponent} from './test/test.component';
import { RequestApprovalComponent } from './request-approval/request-approval.component';
const routes: Routes = [
 
  {path:'home', component:IsrComponent,canActivate: [AuthGuard] },
  {path:'ro', component:RequestOverviewComponent,canActivate: [AuthGuard]},
  //{path:'header', component:HeaderComponent},  
  {path:'reqView', component:RequestViewComponent,canActivate: [AuthGuard]},
  {path:'projRefAndBudgetView', component:ProjRefAndBudgetViewComponent,canActivate: [AuthGuard]},
  {path:'sendRequestView', component:SendRequestViewComponent,canActivate: [AuthGuard]},
  {path:'request/:requestNo', component:RequestDetailsComponent,canActivate: [AuthGuard]},
  {path:'change/:changeId', component:ChangeInfoComponent,canActivate: [AuthGuard]},
  {path:'createNewRequest', component:CreateNewRequestComponent,canActivate: [AuthGuard]},
  {path:'createRequestInfo/:requestType', component:CreateRequestInfoComponent,canActivate: [AuthGuard]},
  {path:'profileInfo', component:ProfileInfoComponent,canActivate: [AuthGuard]},
  {path:'login', component:LoginComponent},
  //{path:'', component:LoginComponent},
  {path:'loginOtp/:email', component:LoginOTPComponent},
  {path:'requestApproval/:requestNumber', component:RequestApprovalComponent,canActivate: [AuthGuard]},
  {path:'**', component:IsrComponent,canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {  
  
  constructor(public router: Router, private menuService:MenuService, private sharedService:SharedService) {    

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        /*if(document.cookie=='' || document.cookie==null ){
          router.navigate(['/login']);
          return;
        }*/
        //Show/hide quickSearch       
          if(ev.url.indexOf('ro') > 0 || ev.url.indexOf('request') > 0){
            //this.menuService._showQuickSearch.next(true);
            if(sharedService.chkUserPerm(31)){
              this.menuService._showQuickSearch.next(true);
            }else{
              this.menuService._showQuickSearch.next(false);
            }
          }else{
            this.menuService._showQuickSearch.next(false);
          }
          
        //Show/hide checklist
        if(ev.url.indexOf('request') > 0){
          this.menuService._isChecklistPresent.next(false);
        }          

        if (ev.url.indexOf('login')>0 || ev.url.indexOf('loginOtp')>0)
        {
          this.menuService._islogin.next(false);
        }
        else
        {
          this.menuService._islogin.next(true);
        }

      }
    });
}
}
